import React, { useState, useEffect } from 'react'
import { useTheme } from 'styled-components'

import imgTickets from '../assets/img-tickets.webp'

import SEO from '../components/SEO'
import Header from '../components/Header'
import Artist from '../components/Artist'
import Footer from '../components/Footer'

import { PageLayout } from '../styles/Layout'
import { LineupStyled } from '../styles/LineupStyled'

const Lineup = () => {
  const theme = useTheme()

  const [isReverse, setIsReverse] = useState(false)

  const changeBackgroundColor = () => {
    const windowScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    const pageHeight = document.body.offsetHeight - (window.innerHeight + 300)

    if (windowScroll >= pageHeight) {
      setIsReverse(true)
    } else {
      setIsReverse(false)
    }
  }

  const onScroll = () => {
    window.requestAnimationFrame(changeBackgroundColor)
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <>
      <SEO
        title="Butik | Lineup"
        description="Butik Electronic Music Festival"
        image={imgTickets}
      />
      <PageLayout
        backgroundColor={
          isReverse ? theme.backgroundPrimary : theme.backgroundDark
        }
      >
        <Header
          textColor={
            isReverse ? theme.backgroundDark : theme.backgroundHighlight
          }
          logoColor={
            isReverse ? theme.backgroundDark : theme.backgroundHighlight
          }
        />
        <LineupStyled>
          <div className="content">
            <div className="artist-list">
              <Artist
                name="Akaj"
                addon="LIVE+DJ"
                link="https://soundcloud.com/jv2000"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Alex Ranerro"
                link="https://soundcloud.com/alex-ranerro"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Amai"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Arkajo"
                addon="LIVE"
                link="https://soundcloud.com/arkajo"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Batu"
                link="https://soundcloud.com/batuuk"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="b2b CCL"
                link="https://soundcloud.com/ccl-url"
                color={theme.textReverse}
                className="inner"
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Binh"
                link="https://soundcloud.com/binh"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Carlos Valdes"
                link="https://soundcloud.com/carlosvaldesmuzik"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Christian AB"
                link="https://soundcloud.com/christian_ab"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="DJ MARIA."
                link="https://soundcloud.com/djmaria-jp"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="DJ Senc"
                link="https://soundcloud.com/senc"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="dj sweet6teen"
                link="https://soundcloud.com/djsweet6teen"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="dvidevat"
                link="https://soundcloud.com/dvidevat"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Eliaz"
                link="https://soundcloud.com/eliazg"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Enrica Falqui"
                link="https://soundcloud.com/enricafalqui"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="b2b John Dimas"
                link="https://soundcloud.com/john-dimas"
                color={theme.textReverse}
                className="inner"
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Eris Drew"
                link="https://soundcloud.com/eris-drew"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Estera"
                link="https://soundcloud.com/esteraslo"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Fafi Abdel Nour"
                link="https://soundcloud.com/fafi_abdel_nour"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Francesco Farfa"
                link="https://soundcloud.com/francescofarfa"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Herzog"
                link="https://soundcloud.com/herzogm"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Hitam"
                link="https://soundcloud.com/hitam-electronix"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Issun-bōshi"
                link="https://soundcloud.com/mmali-music"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Izza"
                link="https://soundcloud.com/izza-music"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Jane Fitz"
                link="https://soundcloud.com/janefitz"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="b2b Paquita Gordon"
                link="https://soundcloud.com/paquitagordon"
                color={theme.textReverse}
                className="inner"
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Jaša Bužinel"
                link="https://soundcloud.com/jasabuzinel"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="John Talabot"
                link="https://soundcloud.com/john-talabot"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="b2b Ryan Elliott"
                link="https://soundcloud.com/ryan-elliott"
                color={theme.textReverse}
                className="inner"
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Josey Rebelle"
                link="https://www.joseyrebelle.com"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Katia Curie"
                link="https://soundcloud.com/katia-curie"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Kia"
                link="https://soundcloud.com/kia-sydney"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Kikelomo"
                link="https://soundcloud.com/kikelomooo"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Lerro"
                link="https://soundcloud.com/ler_ro"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="b2b Polner"
                link="https://soundcloud.com/polner"
                color={theme.textReverse}
                className="inner"
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="livwutang"
                link="https://soundcloud.com/livwutang"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Lottie"
                link="https://soundcloud.com/loti-kavsek"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Mande"
                link="https://soundcloud.com/nikolamande"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Marco Shuttle"
                link="https://soundcloud.com/marco_shuttle"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Marie K"
                link="https://soundcloud.com/marie-k-music"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Marie Pravda"
                link="https://soundcloud.com/yomomsmokescrack"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Masha Gasar"
                link="https://soundcloud.com/mashag01"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Maša"
                link="https://soundcloud.com/mderno"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Matorola"
                link="https://soundcloud.com/matorola"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="b2b Slishko"
                link="https://soundcloud.com/slishko"
                color={theme.textReverse}
                className="inner"
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Matthew Dexter"
                link="https://soundcloud.com/matthewdextersound"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Mayell"
                link="https://soundcloud.com/mayellmusic"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="b2b Tim Kern"
                link="https://soundcloud.com/tim-kern-1"
                color={theme.textReverse}
                className="inner"
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="b2b Tzena"
                link="https://soundcloud.com/tzenamusic"
                color={theme.textReverse}
                className="inner"
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Melody"
                link="https://soundcloud.com/melody-9"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Modus Operandi"
                link="https://soundcloud.com/robin-hundman"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Moopie"
                link="https://soundcloud.com/moopie"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Nikola"
                link="https://www.instagram.com/marrrinkovic"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Nikolaj"
                link="https://soundcloud.com/jal0kin"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Nizar Sarakbi"
                link="https://soundcloud.com/nizar-sarakbi"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="O.BEE"
                link="https://soundcloud.com/o-bee"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="& Tomas Station"
                link="https://soundcloud.com/tomasstation"
                color={theme.textReverse}
                className="inner"
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Obscur"
                link="https://soundcloud.com/0bscur"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="b2b Unknown Texture"
                link="https://soundcloud.com/unknowntexture"
                color={theme.textReverse}
                className="inner"
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="OK Williams"
                link="https://soundcloud.com/okwilliams"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Pariah"
                link="https://soundcloud.com/pariah_uk"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="b2b Verraco"
                link="https://soundcloud.com/verraco"
                color={theme.textReverse}
                className="inner"
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Peach"
                link="https://soundcloud.com/ohpeach"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Priori"
                addon="LIVE+DJ"
                link="https://soundcloud.com/priori-ties"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="RotorMotor"
                link="https://soundcloud.com/rotormotor"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Sahara Transport Services"
                link="https://soundcloud.com/saharatransportservices"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Sedef Adasï"
                link="https://soundcloud.com/sedefadasi"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Simm."
                link="https://soundcloud.com/simm2323"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="b2b Joan Hong"
                link="https://soundcloud.com/jessihong"
                color={theme.textReverse}
                className="inner"
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Sugar Free"
                link="https://soundcloud.com/freesugarfree"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Sumi"
                link="https://soundcloud.com/dimsumi"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="SunnySun"
                link="https://soundcloud.com/sunnysun"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Sybil"
                link="https://soundcloud.com/syberkid"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Teo"
                link="https://soundcloud.com/teothedj"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Tunik"
                link="https://soundcloud.com/tunik303"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Vasko"
                link="https://soundcloud.com/vaskotabasko"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Victor"
                link="https://soundcloud.com/victormgcpwr"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="vince"
                link="https://soundcloud.com/leo_vince"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Vlada"
                link="https://soundcloud.com/playvlada"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="Zvèn"
                link="https://soundcloud.com/zvven"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
              <Artist
                name="93kb"
                link="https://soundcloud.com/brank-klemen"
                color={theme.textReverse}
                overlayBackground={
                  isReverse ? theme.backgroundPrimary : theme.backgroundDark
                }
              />
            </div>
            <Footer isFadedOut={!isReverse} />
          </div>
        </LineupStyled>
      </PageLayout>
    </>
  )
}

export default Lineup
