import styled from 'styled-components'

import breakpoints from '../constants/breakpoints'

const LineupStyled = styled.div`
  height: 100%;

  .content {
    position: relative;
    overflow-x: hidden;
  }

  .artist-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 27vh 10vw 30vh 15vw;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 25% 4vw 6%;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      padding: 35% 5vw 0;
    }
  }
`

export { LineupStyled }
